<template>
  <div class="my-loan">
    <div class="main">
      <div class="top flex-column-start">
        <div class="title">{{ $t('wo-de-dai-kuan') }}</div>
        <div class="money">{{ loanMoney }}</div>
        <div class="flex-center-start full-width">
          <div class="flex-column-center box">
            <div class="count">0{{ $t('tian') }}</div>
            <div class="label">{{ $t('yi-dai-kuan') }}</div>
          </div>
          <div class="flex-column-center box">
            <div class="count">0</div>
            <div class="label">{{ $t('chan-sheng-li-xi') }}</div>
          </div>
          <div class="flex-column-center box">
            <div class="count">0</div>
            <div class="label">{{ $t('yu-ji-huan-kuan') }}</div>
          </div>
        </div>
      </div>

      <div class="page-title">{{ $t('dai-kuan-ji-lu') }}</div>

      <div class="loan-list">
        <div
          class="list flex-center-start"
          v-for="(item, i) in dataList"
          :key="i"
        >
          <div class="icon">
            <img :src="loanUrl" alt="" />
          </div>
          <div class="flex-center-between mr-30">
            <div>
              <div class="name">5555.00</div>
              <div class="time">
                {{ $t('shen-qing-ri-qi') }}2024-11-16 22:02:55
              </div>
            </div>
          </div>
          <div class="flex-column-center mr-60">
            <div class="count">60{{ $t('tian-0') }}</div>
            <div>{{ $t('huan-kuan-zhou-qi') }}</div>
          </div>
          <div class="flex-column-center mr-60">
            <div class="count">0.3%</div>
            <div>{{ $t('dai-kuan-li-shuai') }}</div>
          </div>
          <div class="status mr-60">{{ $t('yi-fang-kuan') }}</div>
          <div class="price">5432.12</div>
        </div>

        <div class="empty-box flex-column-center" v-if="dataList.length == 0">
          <img :src="emptyUrl" alt="" />
          <span>{{ $t('zan-wu-shu-ju') }}</span>
        </div>
      </div>

      <div class="flex-center user-pager mt-20" v-if="dataList.length > 0">
        <el-pagination
          background
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout=" prev, pager,  next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { List } from 'vant'
import { getToken } from '@/utils/auth'
export default {
  components: {},
  data() {
    return {
      loanUrl: require('@/assets/imgs/icon-loan.png'),
      loanMoney: 0,
      dataList: [{}],
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      page: {
        current: 1,
        size: 10,
        total: 1
      },
      isInit: false,
      loading: false,
      isLoading: false,
      finished: false,
      totalPage: 1
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    }
  },
  mounted() {},
  methods: {
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      this.finished = true
      return
      this.loading = true
      // 店铺列表
      getShopFollowList({
        current: this.page.current,
        pageSize: this.page.size
      })
        .then((res) => {
          if (isAdd) {
            this.dataList = this.dataList.concat(res.data.Items)
          } else {
            this.dataList = res.data.Items
          }
          this.totalPage = res.data.Pagination
            ? res.data.Pagination.totalPage
            : 1
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
          this.isInit = true
          this.loading = false
        })
        .catch((err) => {
          this.isInit = true
          this.loading = false
        })
    },
    back() {
      this.$router.go(-1)
    },
    currentChange(page) {
      this.page.current = page
    }
  }
}
</script>